import React from 'react';
import logo from './logo.svg';
import Game from './game/game';
import './App.css';

function App() {
  return (
    <Game></Game>
  );
}

export default App;
